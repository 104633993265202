<template>
    <div :class="{ 'form-loading': loading }">
        <window-header></window-header>
        <nav style="justify-content: space-between;">
            <button class="button button-fill" @click="abrirConfiguracion">
                <fa-icon icon="filter" /><span>Filtros</span>
            </button>
            <div style="display: flex;">
                <label style="display: flex; align-items: center;">
                    <input type="checkbox" name="inactivos" v-model="mostrarInactivos" @change="actualizarArticulos">
                    <span style="margin-left: 5px;">Mostrar inactivos</span>
                </label>
                <label style="display: flex; align-items: center; margin-left: 15px;">
                    <input type="checkbox" name="sin_stock" v-model="mostrarSinStock" @change="actualizarArticulos">
                    <span style="margin-left: 5px;">Mostrar sin stock</span>
                </label>
            </div>
            <button class="button button-fill" @click="guardar">
                <fa-icon icon="save" /> <span>Guardar</span>
            </button>
        </nav>
        <section>
            <article style="height: 536px; width: 975px">
                <div ref="hotTableComponent"></div>
            </article>
        </section>
    </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import Handsontable from "handsontable";

export default {
    mixins: [WindowMixin],
    data: function () {
        return {
            title: "Editor masivo de atributos",
            hotInstance: null,
            loading: false,
            mostrarInactivos: false,
            mostrarSinStock: false
        };
    },
    methods: {
        guardar() {
            let self = this;
            let data = self.hotInstance.getSourceData().filter(d => d.changed)
            if (!data.length) {
                self.app.toast("No se ha modificado ninguna línea", "error")
                return;
            }
            let p = Promise.resolve();
            data.forEach(item => {
                let atributos = Object.entries(item.valores).filter(x => x[1]).map(x => x[0])
                p = p.then(window.DB.put('articulo', { itemId: item.codigo, data: { atributos: atributos?.length ? atributos : false, palabras_clave: item.palabras_clave } }));
            })
            p
                .catch(e => self.app.toast("Error al actualizar los artículos", "error"))
                .then(_ => {
                    self.app.toast("Artículos actualizados", "success")
                    // Actualizar atributos
                    self.loading = true;
                    window.DB.getList('atributo', { order: 'id', fields: ['nombre', { name: 'valores', fields: ['nombre', { name: 'articulos', fields: ['codigo'] }] }] }).then(resAtr => {
                        self.valData = resAtr.data.reduce((acc, v) => [...acc, ...v.valores], [])
                    }).finally(_ => self.loading = false);
                })
        },
        abrirConfiguracion() {
            var self = this;
            self.app.openChildWindow("articulos_atributos_config", self.win, {
                backdrop: true,
                //model: self.model,
                props: {
                    parent: self,
                },
            });
        },
    },
    mounted() {
        var self = this;

        self.valData = [];

        self.filtro = null;

        self.actualizarArticulos = (forceVal) => {
            self.loading = true;
            let filter = [];
            if (!self.mostrarInactivos) filter.push(['activo', '=', 1])
            if (!self.mostrarSinStock) filter.push(['stock', '>', 0])
            if (self.filtro) filter.push(self.filtro);
            window.DB.getList('articulo', { filter, order: 'codigo', fields: [{ name: 'thumbnail', fields: ['imagen'] }, 'codigo', 'nombre', 'fecha_ultima_compra', 'palabras_clave'] }).then(resArt => {
                let data = resArt.data.map(a => ({ ...a, ...{ valores: self.valData.reduce((acc, r) => ({ ...acc, ...{ [r.id]: (r.articulos.some(q => q.codigo == a.codigo) ? 1 : 0) } }), {}), changed: false } }));
                self.hotInstance.loadData(data);
            }).finally(_ => self.loading = false)
        }


        window.DB.getList('atributo', { order: 'id', fields: ['nombre', { name: 'valores', fields: ['nombre', { name: 'articulos', fields: ['codigo'] }] }] }).then(resAtr => {
            self.valData = resAtr.data.reduce((acc, v) => [...acc, ...v.valores], [])
            const rowHeaderWidth = 40;
            const checkboxWidth = 35;
            const codigoWidth = 100;
            const ucompraWidth = 70;
            const palabrasClaveWidth = 200;
            const descripcionWidth = Math.max(975 - rowHeaderWidth - 10 - codigoWidth - ucompraWidth - self.valData.length * checkboxWidth - palabrasClaveWidth, 200);
            const nestedHeaders = [
                [{ label: 'Artículo', colspan: 3 }, ...resAtr.data.reduce((acc, at) => [...acc, { label: at.nombre, colspan: at.valores.length }], [])],
                ['Código', 'Descripción', 'U.Compra', ...self.valData.map(v => v.nombre), 'Palabras clave']
            ];
            const colWidths = checkboxWidth;
            const checkbox = { type: "checkbox", checkedTemplate: 1, uncheckedTemplate: 0, allowInvalid: false, allowEmpty: false, className: 'text-align-center' };
            let columns = [
                {
                    data: 'codigo',
                    readOnly: true,
                    width: codigoWidth,
                    renderer: function (hotInstance, td, row, column, prop, value, cellProperties) {
                        Handsontable.renderers.TextRenderer.apply(this, arguments);
                        let r = self.hotInstance?.toPhysicalRow(row) || row;
                        if (hotInstance.getSourceDataAtRow(r).changed) td.style.backgroundColor = '#fff3bb';
                    },
                    columnSorting: {
                        compareFunctionFactory: function compareFunctionFactory(sortOrder, columnMeta) {
                            return function comparator(a, b) {
                                //window.console.log(a, b, sortOrder, (a[order] > b[order] ? 1 : (a[order] < b[order] ? -1 : 0)) * (sortOrder == 'asc' ? 1 : -1))
                                return (a > b ? 1 : (a < b ? -1 : 0)) * (sortOrder == 'asc' ? 1 : -1);
                            };
                        }
                    }
                },
                { data: 'nombre', readOnly: true, width: descripcionWidth },
                { data: 'fecha_ultima_compra', type: 'date2', readOnly: true, width: ucompraWidth },
                ...self.valData.map(r => ({ ...{ data: 'valores.' + r.id }, ...checkbox })),
                { data: 'palabras_clave', width: palabrasClaveWidth }
            ];

            self.hotInstance = new Handsontable(self.$refs.hotTableComponent, {
                licenseKey: 'non-commercial-and-evaluation',
                //autoRowSize: { syncLimit: 300 },
                dataSchema: { codigo: null, nombre: null, valores: {} },
                multiSelect: true,
                rowHeaders: true,
                width: 975,
                height: 535,
                columnSorting: true,
                sortIndicator: true,
                manualColumnResize: true,
                manualColumnMove: true,
                fillHandle: {
                    //direction: 'vertical',
                    autoInsertRow: false,
                },
                fixedColumnsLeft: 2,
                columns,
                nestedHeaders,
                colWidths,
                data: [],
                minRows: 0,
                minSpareRows: 0,
                beforeChange: (changes, source) => changes.forEach(c => changes.push([c[0], 'changed', null, 1])),
                rowHeights: 21,
                enterBeginsEditing: false,
                autoWrapCol: false,
                autoWrapRow: false,
                rowHeaderWidth,
                afterSelectionEnd: row => {
                    row = self.hotInstance?.toPhysicalRow(row) || row;
                    if (parseInt(self.app.config.mostrar_imagen_articulos_ocultos)) {
                        let h = self.hotInstance;
                        let articulo_id = h.getSourceData()[row].codigo;
                        if (!articulo_id) return;
                        let a = h.getSourceData()[row];
                        if (self.win.children.length && self.win.children.some(ch => ch.window_name == 'popup_imagen')) {
                            let popupImagen = self.win.children.find(ch => ch.window_name == 'popup_imagen');
                            if (popupImagen.instance) popupImagen.instance.articulo = a;
                            else popupImagen.opts.articulo = a;
                        } else {
                            self.app.openChildWindow('popup_imagen', self.win, {
                                backdrop: false,
                                resizable: true,
                                articulo: a
                            })
                        }
                    }
                },
                /*beforeColumnSort: function (currentSortConfig, destinationSortConfigs) {
                  let h = self.hotInstance;
                  h.deselectCell();
                  let data = h.getSourceData();
        
                  let order = h.colToProp(destinationSortConfigs[0].column);
                  let orderDir = "ASC" ? "DESC" : "ASC";
                  window.console.log(destinationSortConfigs)
        
                  data.sort((a, b) => (a[order] > b[order] ? 1 : (a[order] < b[order] ? -1 : 0)) * (orderDir == 'ASC' ? 1 : -1));
                  self.cleanData(data, false);
        
                  self.setValue(data.length ? [...data] : false);
                  setTimeout(() => window.console.log(self), 500);
                  self.onChange();
                  return false;
                }*/
            });
            self.actualizarArticulos();
        })
    },
};
</script>
<style>
.text-align-center {
    text-align: center;
}
</style>